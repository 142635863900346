@import '../node_modules/@tdoe/design-system/assets/theme/variables';
@import '../node_modules/@angular/material/prebuilt-themes/purple-green.css';
@import '../node_modules/@tdoe/design-system/assets/theme/all.scss';

.flex-spacer {
    flex: 1 1 auto;
}

.w-50 {
    width: 50%;
}

.mat-form-field-label {
    color: $c-ui-middle;
}

.mat-form-field-underline::before {
    background-color: $c-ui-middle !important;
}

.mat-button-toggle-checked {
    background-color: $c-primary-light !important;
    color: $c-neutral-stark !important;
}

body {
    margin: 0 !important;
}
.mat-table {
  .mat-header-cell, .mat-cell {
      padding:0 16px;
  }
}
